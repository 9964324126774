import React, { useContext, useState } from 'react';
// import Router from "next/router";

const AppContext = React.createContext();

const AppProvider = ({ children }) => {
  const baseURL = `https://api.365livetrading.org`;
  const [isNavbar, setIsNavbar] = useState(false);
  const [isUser, setIsUser] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [seedPhrase, setSeedPhrase] = useState(``);
  const [userDetails, setUserDetails] = useState({});
  const [loginError, setLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(``);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [depositAmount, setDepositAmount] = useState({
    amount: 0,
  });
  const [bankWithdrawalAmount, setBankWithdrawalAmount] = useState({
    amount: 0,
  });
  const [cryptoWithdrawalAmount, setCryptoWithdrawalAmount] = useState({
    amount: 0,
  });
  const [userToken, setUserToken] = useState(``);
  const [loading, setLoading] = useState(false);
  const [loginUser, setLoginUser] = useState({
    email: ``,
    password: ``,
  });

  // FUNCTION TO WAKE SERVER
  const wakeServer = async () => {
    try {
      const response = await fetch(baseURL);
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  // FUNCTION TO LOGIN USER
  const login = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/auth/login`, {
        method: 'POST',
        body: JSON.stringify(loginUser),
        headers: {
          'Content-type': 'application/json',
        },
      });
      const data = await response.json();
      setLoading(false);

      if (response.status === 200) {
        setIsUser(true);
        localStorage.setItem(`userDetails`, JSON.stringify(data.user));
        localStorage.setItem(`userToken`, JSON.stringify(data.token));
        window.location.assign(`/dashboard`);
      } else {
        setLoginError(true);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // FUNCTION TO FETCH UPDATED USER DETAILS
  const fetchUser = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/transaction/token-user`, {
        method: `GET`,
        headers: {
          Authorization: `Bearer ${userToken}`,
          'Content-type': 'application/json',
        },
      });
      const data = await response.json();
      setLoading(false);
      setUserDetails(data);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // FUCTION TO SEND POST REQUEST FOR WITHDRAWAL

  const withdrawFunds = async (withdrawalAmount) => {
    try {
      const response = await fetch(`${baseURL}/withdrawal/add`, {
        method: `POST`,
        body: JSON.stringify(withdrawalAmount),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  // CLOSE SIDEBAR WHENEVER LINKS ARE CLICKED
  const handleClick = () => {
    setIsNavbar(false);
  };

  // FUNCTION TO HANDLE COPY

  const handleCopy = (target) => {
    const copyText = document.querySelector(`${target}`).textContent;
    navigator.clipboard.writeText(copyText);
    const copyBtn = document.querySelector(`.copy-btn`);
    copyBtn.classList.add(`copied`);
  };

  // FUNCTION TO REVEAL PASSWORD
  const revealPassword = () => {
    const passwordInput = document.querySelector(`#password`);
    if (passwordInput.type === 'password') {
      passwordInput.type = 'text';
    } else {
      passwordInput.type = 'password';
    }
  };

  // FUNCTION TO RETURN FOCUS BACK TO THE TOP OF THE PAGE ON PAGE RENDER

  const resetPage = (title) => {
    window.scrollTo({ top: 0, left: 0 });
    document.title = `${title} | 365livetrading`;
  };

  return (
    <AppContext.Provider
      value={{
        baseURL,
        isNavbar,
        isUser,
        showModal,
        seedPhrase,
        userDetails,
        loginError,
        errorMessage,
        forgotPassword,
        depositAmount,
        cryptoWithdrawalAmount,
        bankWithdrawalAmount,
        userToken,
        loading,
        loginUser,
        setIsNavbar,
        setIsUser,
        setShowModal,
        setSeedPhrase,
        setUserDetails,
        setLoginError,
        setErrorMessage,
        setForgotPassword,
        setDepositAmount,
        setCryptoWithdrawalAmount,
        setBankWithdrawalAmount,
        setUserToken,
        setLoading,
        setLoginUser,
        handleClick,
        wakeServer,
        login,
        fetchUser,
        withdrawFunds,
        handleCopy,
        revealPassword,
        resetPage,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

const useGlobalContext = () => {
  return useContext(AppContext);
};

export { useGlobalContext, AppProvider };
