// FORM VALIDATION

// EMAIL VALIDATION
const validateEmail = (string) => {
  const emailInput = document.querySelector(`#${string}`);
  if (emailInput.value.length === 0) {
    emailInput.classList.add(`red`);
    emailInput.nextSibling.textContent = `Email field cannot be empty`;
    return false;
  }
  if (
    !emailInput.value.match(
      /[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9 -]+\.[a-z ]{2,}/
    )
  ) {
    emailInput.classList.add(`red`);
    emailInput.nextSibling.textContent = `Use a valid email`;
    return false;
  }
  emailInput.classList.remove(`red`);
  emailInput.nextSibling.textContent = `${
    string === `referral-email` ? `Referral Email` : `Email`
  } `;
};

// NAME VALIDATION-
const validateName = (string) => {
  const nameInput = document.querySelector(`#${string}`);

  if (nameInput.value.length === 0) {
    nameInput.classList.add(`red`);
    nameInput.nextSibling.textContent = `Name field cannot be empty`;
    return false;
  }
  if (nameInput.value.match(/[0-9]/)) {
    nameInput.classList.add(`red`);
    nameInput.nextSibling.textContent = `Numbers are not allowed`;
    return false;
  }
  if (nameInput.value.match(/[.!#$%&'*+/=?^_`{|}~-]/)) {
    nameInput.classList.add(`red`);
    nameInput.nextSibling.textContent = `Symbols are not allowed`;
    return false;
  }

  if (!nameInput.value.match(/(\w.+\s).+/)) {
    nameInput.classList.add(`red`);
    nameInput.nextSibling.textContent = `Enter First name and Last Name`;
    return false;
  }

  nameInput.classList.remove(`red`);
  nameInput.nextSibling.textContent = `Your Name`;
};

// PASSWORD VALIDATION
const validatePassword = (string) => {
  const passwordInput = document.querySelector(`#${string}`);

  if (passwordInput.value.length === 0) {
    passwordInput.classList.add(`red`);
    passwordInput.nextSibling.textContent = `Password field cannot be empty`;
    return false;
  }
  if (!passwordInput.value.match(/[a-zA-Z0-9!@#$%^&*]{8,}/)) {
    passwordInput.classList.add(`red`);
    passwordInput.nextSibling.textContent = `Minimum of 8 Characters`;
    return false;
  }

  passwordInput.classList.remove(`red`);
  passwordInput.nextSibling.textContent = `Password`;
};

// RESET PASSWORD VALIDATION
const validateResetPassword = (string) => {
  const passwordInput = document.querySelector(`#${string}`);

  if (passwordInput.value.length === 0) {
    passwordInput.classList.add(`red`);
    passwordInput.nextSibling.textContent = `New password field cannot be empty`;
    return false;
  }
  if (!passwordInput.value.match(/[a-zA-Z0-9!@#$%^&*]{8,}/)) {
    passwordInput.classList.add(`red`);
    passwordInput.nextSibling.textContent = `Minimum of 8 Characters`;
    return false;
  }

  passwordInput.classList.remove(`red`);
  passwordInput.nextSibling.textContent = `New Password`;
};

// CONFIRM PASSWORD VALIDATION
const validateSecondPassword = (string) => {
  const confirmPasswordInput = document.querySelector(`#${string}`);
  const passwordInput = document.querySelector(`#password`);

  if (confirmPasswordInput.value !== passwordInput.value) {
    confirmPasswordInput.classList.add(`red`);
    confirmPasswordInput.nextSibling.textContent = `New password does not match`;
    return false;
  }
  confirmPasswordInput.classList.remove(`red`);
  confirmPasswordInput.nextSibling.textContent = `Confirm Password`;
};

// VALIDATE NUMBER
const validateNumber = (string) => {
  const numberInput = document.querySelector(`#${string}`);

  if (numberInput.value.length === 0) {
    numberInput.nextSibling.textContent = `Phone number field cannot be empty`;
    numberInput.classList.add(`red`);
    return false;
  }
  if (numberInput.value.match(/[a-zA-Z!@#$%^&*]/)) {
    numberInput.classList.add(`red`);
    numberInput.nextSibling.textContent = `Numbers only`;
    return false;
  }

  numberInput.classList.remove(`red`);
  numberInput.nextSibling.textContent = `Phone Number`;
};

// VALIDATE ZIP CODE
const validateDepositAmount = (string) => {
  const depositAmountInput = document.querySelector(`#${string}`);

  if (depositAmountInput.value.length === 0) {
    depositAmountInput.nextSibling.textContent = `Deposit Amount field cannot be empty`;
    depositAmountInput.classList.add(`red`);
    return false;
  }

  depositAmountInput.classList.remove(`red`);
  depositAmountInput.nextSibling.textContent = ``;
};

// VALIDATE ZIP CODE
const validateZipCode = (string) => {
  const zipCodeInput = document.querySelector(`#${string}`);

  if (zipCodeInput.value.length === 0) {
    zipCodeInput.nextSibling.textContent = `ZIp Code field cannot be empty`;
    zipCodeInput.classList.add(`red`);
    return false;
  }
  if (zipCodeInput.value.match(/[a-zA-Z!@#$%^&*]/)) {
    zipCodeInput.classList.add(`red`);
    zipCodeInput.nextSibling.textContent = `Numbers only`;
    return false;
  }

  zipCodeInput.classList.remove(`red`);
  zipCodeInput.nextSibling.textContent = `Zip Code`;
};

// WALLET VALIDATION
const validateWallet = (string) => {
  const walletInput = document.querySelector(`#${string}`);

  if (walletInput.value.length === 0) {
    walletInput.classList.add(`red`);
    walletInput.nextSibling.textContent = `Wallet field cannot be empty`;
    return false;
  }
  if (!walletInput.value.match(/[a-zA-Z0-9]{24,}/)) {
    walletInput.classList.add(`red`);
    walletInput.nextSibling.textContent = `Enter a valid Address`;
    return false;
  }

  walletInput.classList.remove(`red`);
  walletInput.nextSibling.textContent = `Wallet Address`;
};

// VALIDATE ACCOUNT NUMBER
const validateACCNumber = (string) => {
  const numberInput = document.querySelector(`#${string}`);

  if (numberInput.value.length === 0) {
    numberInput.nextSibling.textContent = `Account number field cannot be empty`;
    numberInput.classList.add(`red`);
    return false;
  }
  if (numberInput.value.match(/[a-zA-Z!@#$%^&*]/)) {
    numberInput.classList.add(`red`);
    numberInput.nextSibling.textContent = `Numbers only`;
    return false;
  }

  if (!numberInput.value.match(/[0-9]{8,}/)) {
    numberInput.classList.add(`red`);
    numberInput.nextSibling.textContent = `Enter a valid Account Number`;
    return false;
  }

  numberInput.classList.remove(`red`);
  numberInput.nextSibling.textContent = `Account Number`;
};

export {
  validateEmail,
  validateName,
  validatePassword,
  validateSecondPassword,
  validateNumber,
  validateZipCode,
  validateResetPassword,
  validateDepositAmount,
  validateWallet,
  validateACCNumber,
};
