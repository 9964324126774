import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import ButtonLoader from "../../components/loaders/ButtonLoader";
import SuccessfulRegistration from "../../components/modals/SuccessfulRegistration";
import { useGlobalContext } from "../../context/context";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FaEyeSlash, FaEye } from "react-icons/fa";

const SignUp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const {
    showModal,
    setShowModal,
    setSeedPhrase,
    isNavbar,
    baseURL,
    resetPage,
    revealPassword,
  } = useGlobalContext();

  // FUNCTION TO REGISTER USER
  const register = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${baseURL}/auth/register`, {
        method: "POST",
        body: JSON.stringify(formik.values),
        headers: {
          "Content-type": "application/json",
        },
      });
      const data = await response.json();
      console.log(data);
      console.log(response.status);
      setIsLoading(false);
      setShowModal(true);
      setSeedPhrase(data.seedPhrase);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    resetPage(`Create Account`);
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      name: ``,
      email: ``,
      password: ``,
      zipCode: ``,
      address: ``,
      countryOfResidence: ``,
    },
    validationSchema: Yup.object({
      email: Yup.string().email(`Invalid Email`).required(`Email is required`),

      name: Yup.string()
        .required(`Fullname is required`)
        .matches(/^[a-zA-Z]+ [a-zA-Z]+$/, "Please enter a valid full name"),

      password: Yup.string()
        .min(8, `Minimum of 8 characters`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
          "At least 1 special character and a number"
        )
        .required("Password is required"),

      zipCode: Yup.string()
        .min(5, `Minimun of 5 characters`)
        .matches(/^\d{5}$/, "Please enter a valid Zip code")
        .required("Zip code is required"),

      address: Yup.string()
        .min(5, "Address must be at least 5 characters long")
        .required("Address is required"),
    }),
    onSubmit: () => {
      register();
    },
  });

  return (
    <>
      <section className='sign-up'>
        <article className='sign-up-modal'>
          <h1>Create your account</h1>
          <form onSubmit={formik.handleSubmit} className='sign-up-form'>
            {/* USER EMAIL */}
            <div className='form-control'>
              <input
                type='email'
                placeholder='johnDoe@email.com'
                id='email'
                className={formik.errors.email ? `red input` : `input`}
                name='email'
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor='email'
                className={isNavbar ? `navOpen label` : `label`}
              >
                {formik.touched && formik.errors.email
                  ? formik.errors.email
                  : `Email`}
              </label>
            </div>

            {/* FULL NAME */}
            <div className='form-control'>
              <input
                type='text'
                placeholder='John Doe'
                id='full-name'
                className={formik.errors.name ? `input red` : "input"}
                name='name'
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor='full-name'
                className={isNavbar ? `navOpen label` : `label`}
              >
                {formik.touched && formik.errors.name
                  ? formik.errors.name
                  : `Full name`}
              </label>
            </div>

            {/* PASSWORD */}
            <div className='form-control'>
              <input
                type='password'
                placeholder='**********'
                id='password'
                className={formik.errors.password ? `input red` : "input"}
                name='password'
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor='password'
                className={isNavbar ? `navOpen label` : `label`}
              >
                {formik.touched && formik.errors.password
                  ? formik.errors.password
                  : `Password`}
              </label>
              <p
                className='reveal-btn'
                onClick={() => {
                  revealPassword();
                  setPasswordVisible(!passwordVisible);
                }}
              >
                {passwordVisible ? <FaEyeSlash /> : <FaEye />}
              </p>
            </div>

            {/* ADDRESS */}
            <div className='form-control'>
              <input
                type='text'
                placeholder='No 2, Joy street, NJ, USA'
                id='address'
                className={formik.errors.address ? `input red` : "input"}
                name='address'
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor='address'
                className={isNavbar ? `navOpen label` : `label`}
              >
                {formik.touched && formik.errors.address
                  ? formik.errors.address
                  : `Address`}
              </label>
            </div>

            {/* ZIP CODE */}
            <div className='form-control'>
              <input
                type='text'
                placeholder='4354543'
                id='zipCode'
                className={formik.errors.zipCode ? `input red` : "input"}
                name='zipCode'
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
              <label
                htmlFor='zipCode'
                className={isNavbar ? `navOpen label` : `label`}
              >
                {formik.touched && formik.errors.zipCode
                  ? formik.errors.zipCode
                  : `Zip Code`}
              </label>
            </div>

            {/* CHECKBOX FOR TERMS AND CONDITIONS */}
            <div className='conditions'>
              <div className='terms-condition'>
                <input
                  type='checkbox'
                  name='termsCondition'
                  id='terms-check'
                  required
                />
                <label htmlFor='terms-condition'>
                  I agree to <b>365livetrading's</b>
                  <Link href='/terms-of-use'> Terms of Service</Link> and
                  <Link href='/privacy-policy'> Privacy Policy</Link>
                </label>
              </div>
            </div>

            {/* EXISTING ACCOUNT/SUBMIT BUTTON DIV */}
            <div className='account-submitBtn'>
              <Link to='/sign-in'>
                <p>Already have an account?</p>
              </Link>
              <div className='btn'>
                <button className='purple'>Create account</button>
                {isLoading && <ButtonLoader />}
              </div>
            </div>
          </form>
        </article>
        {showModal && <SuccessfulRegistration />}
      </section>
    </>
  );
};

export default SignUp;
