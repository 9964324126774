import ButtonLoader from "../../components/loaders/ButtonLoader";
import ForgotPassword from "../../components/forgot-password/ForgotPassword";
import { useGlobalContext } from "../../context/context";
import { validateEmail, validatePassword } from "../../data/formValidation";
import { useEffect } from "react";

const SignIn = () => {
  const {
    errorMessage,
    loginError,
    setForgotPassword,
    forgotPassword,
    loading,
    setLoginUser,
    loginUser,
    login,
    isNavbar,
    resetPage,
  } = useGlobalContext();
  useEffect(() => {
    resetPage(`Login`);
    // eslint-disable-next-line
  }, [forgotPassword]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setLoginUser({ ...loginUser, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    login();
  };

  if (forgotPassword) {
    return <ForgotPassword />;
  }

  return (
    <>
      <section className='sign-in'>
        <div className='form'>
          <h1>365livetrading Login</h1>
          <form onSubmit={handleSubmit}>
            {/* EMAIL INPUT */}
            <div className='form-control'>
              <input
                type='email'
                placeholder='johnDoe@email.com'
                id='email'
                className='input'
                name='email'
                value={loginUser.email}
                onChange={handleChange}
                onKeyUp={() => validateEmail(`email`)}
                required
              />
              <label
                htmlFor='email'
                className={isNavbar ? `navOpen label` : `label`}
              >
                Email
              </label>
            </div>

            {/* PASSWORD INPUT */}
            <div className='form-control'>
              <input
                type='password'
                placeholder='**********'
                id='password'
                className='input'
                name='password'
                value={loginUser.password}
                onChange={handleChange}
                onKeyUp={() => validatePassword(`password`)}
                required
              />
              <label
                htmlFor='password'
                className={isNavbar ? `navOpen label` : `label`}
              >
                Password
              </label>
            </div>

            {/* ERROR MESSAGE */}
            {loginError && (
              <div className={`error ${loginError && `show-error`}`}>
                <p>{errorMessage}</p>
              </div>
            )}

            {/* LOGIN BUTTON */}
            <div className='password-submitBtn'>
              <p onClick={() => setForgotPassword(true)}>Forgot password?</p>
              <div className='btn'>
                <button className='purple'>Sign in</button>
                {loading && <ButtonLoader />}
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default SignIn;
