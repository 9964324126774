import { useEffect } from "react";
import { Link } from "react-router-dom";
import { getStartedCards, whatsNext, customerReviews } from "../data/data";
import { useGlobalContext } from "../context/context";

// IMAGES
import BeginJourney from "../assets/begin-journey.webp";
import Services from "../assets/services.webp";
import Security from "../assets/security.webp";
import ClientSupport from "../assets/client-support.webp";

// VIDEO
import Video from "../assets/video/homepage-video.mp4";

export default function Home() {
  const { wakeServer, resetPage } = useGlobalContext();

  useEffect(() => {
    wakeServer();
    resetPage(`Bitcoin and Crypto Trading Platform`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <main className='home'>
        <Block>
          <div className='text'>
            <h1>Invest in Digital Assets</h1>
            <p className='header-p'>
              Sign up today and invest in over <span>50+ cryptocurrencies</span>{" "}
              in minutes. Get started in your dream life with little{" "}
              <span>investments</span>
            </p>
            <div className='btn'>
              <Link to='/sign-up'>
                <button className='purple'>Get Started</button>
              </Link>
            </div>
          </div>
          <div className='video'>
            <video autoPlay loop muted src={Video}></video>
          </div>
        </Block>

        <section className='get-started'>
          <h2>365livetrading is a crypto investment platform for everyone.</h2>
          <h4>How can we help you get started?</h4>
          <Cards data={getStartedCards} />
        </section>

        <Block extraClass={`sec`}>
          <div className='image'>
            <img src={BeginJourney} alt='begin journey' />
          </div>
          <div className='text'>
            <h2>
              New to Bitcoin and cryptocurrencies? <span>No problem.</span>
            </h2>
            <h5>Crypto may seem confusing, but we&apos;re here to help.</h5>
            <h5>Our crypto guides will help get you up to speed.</h5>
            <p>
              While the concept is simple, we know that aspects of
              cryptocurrency can seem a bit complex. Our resource center will
              help guide you through the basics of trading crypto and how
              it&apos;s changing the way the world thinks about money and
              finance.
            </p>
            <div className='btn'>
              <Link to='/guides'>
                <button className='purple'>
                  Learn more about cryptocurrency
                </button>
              </Link>
            </div>
          </div>
        </Block>

        <Block extraClass={`sec everything-you-need`}>
          <div className='text'>
            <h2>
              365livetrading has everything you need{" "}
              <span>to buy, sell, and trade crypto.</span>
            </h2>
            <h5>An intuitive experience from the start.</h5>
            <p>
              From day one, we designed and built a streamlined bitcoin exchange
              for newcomers and experts alike. Make easy deposits and
              withdrawals, measure your portfolio&apos;s performance and keep
              track of all of your crypto in one convenient place.
            </p>
            <div className='btn'>
              <Link to='/services'>
                <button className='purple'>See our services</button>
              </Link>
            </div>
          </div>
          <div className='image'>
            <img src={Services} alt='services' />
          </div>
        </Block>

        <section className='testimonies'>
          <h2>What Our Users Say</h2>
          <h4>See what our customer say about us</h4>
          <div className='customers'>
            {customerReviews.map((customer, index) => {
              const { profileImg, name, review } = customer;
              return (
                <div className='customer' key={index}>
                  <div className='profile-img'>
                    <img src={profileImg} alt={name} className='img' />
                  </div>
                  <h4 className='name'>{name}</h4>
                  <p className='review'>{review}</p>
                </div>
              );
            })}
          </div>
        </section>

        <Block extraClass={`sec security`}>
          <div className='image'>
            <img src={Security} alt='sercurity' />
          </div>
          <div className='text'>
            <h2>Trade with confidence.</h2>
            <h5>365livetrading believes in security above everything.</h5>
            <p>
              Earning the trust of our clients has always been our highest
              priority. We earn that trust through the best security in the
              business — most of our crypto is held safely in cold wallets so
              bad actors can&apos;t reach it. Our platform provides world class
              financial stability by maintaining full reserves, healthy banking
              relationships and the highest standards of legal compliance.
            </p>
            <div className='btn'>
              <Link to='/privacy-policy'>
                <button className='purple'>Check our Privacy Policy</button>
              </Link>
            </div>
          </div>
        </Block>

        <Block extraClass={`sec support-team`}>
          <div className='text'>
            <h2>
              Get help from a 24/7
              <span> global support team.</span>
            </h2>
            <h5>Global 24/7/365 support.</h5>
            <p>
              We pair our global 24/7/365 live chat with an extensive Support
              Center to help ensure your questions are answered and your needs
              are met around the clock -- no matter who or where you are.
            </p>
            <div className='btn'>
              <Link to='/contact'>
                <button className='purple'>Checkout our Support Center</button>
              </Link>
            </div>
          </div>
          <div className='image'>
            <img src={ClientSupport} alt='client support' />
          </div>
        </Block>

        <section className='whats-next'>
          <h2>Keep sailing the high seas of success!.</h2>
          <h4>What&apos;s next?</h4>
          <Cards data={whatsNext} />
        </section>
      </main>
    </>
  );
}

const Block = ({ children, extraClass }) => {
  return <section className={`block ${extraClass}`}>{children}</section>;
};

const Cards = ({ data }) => {
  return (
    <div className='cards'>
      {data.map((card, index) => {
        const { link, text } = card;
        return (
          <div className='card' key={index}>
            <Link to={link}>{text}</Link>
          </div>
        );
      })}
    </div>
  );
};
