import { useGlobalContext } from "../../context/context";
import WithdrawalRequest from "../modals/WithdrawalRequest";
import { useState } from "react";
import Loader from "../loaders/Loader";
import { validateWallet, validateACCNumber } from "../../data/formValidation";

export const BankWithdrawal = () => {
  const {
    bankWithdrawalAmount,
    setBankWithdrawalAmount,
    showModal,
    setShowModal,
    userDetails,
    isNavbar,
  } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal(true);
    }, 3000);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setBankWithdrawalAmount({
      ...bankWithdrawalAmount,
      [name]: parseInt(value),
    });
  };

  return (
    <article className='bank-withdrawal'>
      <h2>Bank Transfer Withdrawal Request</h2>
      <form onSubmit={handleSubmit}>
        {/* ACCOUNT NUMBER */}
        <div className='form-control'>
          <input
            type='text'
            placeholder='Account Number'
            id='acc-no'
            className='input'
            name='acc-no'
            onKeyUp={() => validateACCNumber(`acc-no`)}
            required
          />
          <label
            htmlFor='acc-no'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Account Number
          </label>
        </div>

        {/* BANK SWIFT CODE */}
        <div className='form-control'>
          <input
            type='text'
            placeholder='Bank Swift Code'
            id='swift-code'
            className='input'
            name='swift-code'
            required
          />
          <label
            htmlFor='swift-code'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Bank Swift Code
          </label>
        </div>

        {/* BANK NAME */}
        <div className='form-control'>
          <input
            type='text'
            placeholder='Bank Name'
            id='bank-name'
            className='input'
            name='bank-name'
            required
          />
          <label
            htmlFor='bank-name'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Bank Name
          </label>
        </div>

        {/* AMOUNT */}
        <div className='form-control'>
          <input
            type='number'
            placeholder='Amount'
            id='amount'
            className='input'
            name='amount'
            required
            value={bankWithdrawalAmount.amount}
            onChange={handleChange}
          />
          <label
            htmlFor='amount'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Amount
          </label>
        </div>
        {bankWithdrawalAmount.amount > userDetails.tradeProfit && (
          <p>Withdrawal amount must not exceed total profits</p>
        )}
        <div className='withdraw-btn'>
          <button
            className='purple'
            disabled={!userDetails.userCanWithdraw && true}
          >
            Make Withdrawal Request
          </button>
        </div>
      </form>
      {loading && (
        <section className='fullscreen-loader'>
          <Loader />
        </section>
      )}
      {showModal && <WithdrawalRequest />}
    </article>
  );
};

export const CryptoWithdrawal = () => {
  const {
    showModal,
    setShowModal,
    cryptoWithdrawalAmount,
    setCryptoWithdrawalAmount,
    userDetails,
    isNavbar,
  } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setShowModal(true);
    }, 3000);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCryptoWithdrawalAmount({
      ...cryptoWithdrawalAmount,
      [name]: parseInt(value),
    });
  };

  return (
    <article className='crypto-withdrawal'>
      <h2>Crypto Withdrawal Request</h2>
      <form onSubmit={handleSubmit}>
        {/* AMOUNT */}
        <div className='form-control'>
          <input
            type='number'
            placeholder='Amount'
            id='amount'
            className='input'
            name='amount'
            value={cryptoWithdrawalAmount.amount}
            onChange={handleChange}
            required
          />
          <label
            htmlFor='amount'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Amount
          </label>
        </div>

        {/* WALLET ADDRESS */}
        <div className='form-control'>
          <input
            type='text'
            placeholder='Wallet Address'
            id='crypto-wallet'
            className='input'
            name='crypto-wallet'
            onKeyUp={() => validateWallet(`crypto-wallet`)}
            required
          />
          <label
            h
            tmlFor='crypto-wallet'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Wallet Address
          </label>
        </div>
        {cryptoWithdrawalAmount.amount > userDetails.tradeProfit && (
          <p>Withdrawal amount must not exceed total profits</p>
        )}
        <div className='withdraw-btn'>
          <button
            disabled={!userDetails.userCanWithdraw && true}
            className='purple'
            type='submit'
            onClick={(e) => {
              if (
                e.currentTarget.parentElement.previousElementSibling.childNodes[0].classList.contains(
                  `red`
                )
              ) {
                e.target.setAttribute("disabled", true);
              }
            }}
          >
            Make Withdrawal Request
          </button>
        </div>
      </form>
      {loading && (
        <section className='fullscreen-loader'>
          <Loader />
        </section>
      )}
      {showModal && <WithdrawalRequest />}
    </article>
  );
};
