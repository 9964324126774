import { useEffect } from "react";
import { useGlobalContext } from "../../context/context";

const About = () => {
  const { resetPage } = useGlobalContext();

  useEffect(() => {
    resetPage(`About Us`);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <section className='about'>
        <div className='block'>
          <h2>
            Building <span>the Internet of money</span>
          </h2>
          <p>
            As one of the largest and oldest Bitcoin trading platforms in the
            world, 365livetrading is consistently named one of the best places
            to buy and sell crypto online, thanks to our excellent service, low
            fees, versatile funding options and rigorous security standards --
            but this is only part of the story. We&apos;ve been on the forefront
            of the blockchain revolution since 2011.
          </p>
        </div>
        <h1>
          Our mission is to accelerate the adoption of cryptocurrency{" "}
          <span>
            so that you and the rest of the world can achieve financial freedom
            and inclusion.
          </span>
        </h1>
        <div className='block'>
          <h2>
            Who <span> we are</span>
          </h2>
          <p>
            Recognizing the importance of Bitcoin from the onset, and
            understanding that the exchange is the most critical part of the
            cryptocurrency ecosystem, Jesse Powell founded 365livetrading to
            give people the means to quickly and securely invest in the space.
            Since then, the company has grown by leaps and bounds with hundreds
            of employees spanning the globe. We&apos;re a diverse group of
            thinkers and doers that are dedicated to making cryptocurrency
            available and accessible to the world and enabling people from all
            walks of life to invest in their independence.
          </p>
        </div>
        <div className='block'>
          <h2>
            What <span> we do</span>
          </h2>
          <h4>Empowering investors</h4>

          <p>
            Whether you&apos;re an advanced trader or a crypto-beginner,
            365livetrading gives you the power to chart your own financial
            course. Our exchange has an ever-growing number of cryptocurrency
            pairs for you to invest in and a slew of tools and features for you
            to leverage as you grow your portfolio.
          </p>
          <h4>Supporting institutions</h4>
          <p>
            From over-the-counter trading to personalized white-glove account
            management, 365livetrading is the premier cryptocurrency investing
            solution for institutions of all sizes. We offer exceptional
            liquidity and competitive pricing for all our markets so you can
            achieve your investment goals quickly and confidently.
          </p>
        </div>
      </section>
    </>
  );
};

export default About;
