import React from "react";
import { useGlobalContext } from "../../context/context";

const Notifications = ({ setShowNotification }) => {
  const { userDetails } = useGlobalContext();

  return (
    <article className='notification'>
      <div className='modal'>
        <h2>Important Notification</h2>
        <p>{userDetails.notification}</p>
        <br />
        <button className='purple' onClick={() => setShowNotification(false)}>
          Okay
        </button>
      </div>
    </article>
  );
};

export default Notifications;
