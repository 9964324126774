import { useEffect } from "react";
import { useGlobalContext } from "../../context/context";
import { Link } from "react-router-dom";

const Account = () => {
  const {
    setIsUser,
    userDetails,
    resetPage,
    setUserToken,
    userToken,
    fetchUser,
  } = useGlobalContext();

  useEffect(() => {
    if (window.localStorage.getItem(`userDetails`)) {
      setIsUser(true);
    }
  }, [setIsUser]);

  useEffect(() => {
    resetPage(`Account`);
    // eslint-disable-next-line
  }, []);

  // GET USER TOKEN FROM LOCAL STORAGE
  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));

    // FETCH USER DETAILS
    if (userToken) {
      fetchUser();
    }
    // eslint-disable-next-line
  }, [userToken]);

  const { email, name, zipCode, address, verified, plan } = userDetails;
  return (
    <section className='account'>
      {/* SECTION CONTAINING ALL THE DETAILS ABOUT THE CUSTOMER */}
      <section className='info-block'>
        <article className='personal-info'>
          <h3 className='article-title'>Personal Information</h3>
          <div className='content'>
            <div>
              <h5>full Name</h5>
              <p className='name'>{name}</p>
            </div>
            <div>
              <h5>Phone Number</h5>
              <p>xxx-xxx-xxx-x</p>
            </div>
            <div>
              <h5>Email Address</h5>
              <p>{email}</p>
            </div>
            <div>
              <h5>Zip Code</h5>
              <p>{zipCode}</p>
            </div>
            <div>
              <h5>Gender</h5>
              <p>-</p>
            </div>
            <div>
              <h5>Residence</h5>
              <p className='address'>{address}</p>
            </div>
            <div>
              <h5>Account Status</h5>
              <p>{verified ? `Verified` : `Not Verified`}</p>
            </div>
            <div>
              <h5>Investment Plan</h5>
              <p className='plan'>{plan}</p>
            </div>
          </div>
          <Link to='/dashboard/upgrade-account'>
            <button className='btn purple'>Upgrade Account</button>
          </Link>
        </article>
        <div className='underline'></div>
      </section>
    </section>
  );
};

export default Account;
