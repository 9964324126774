import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGlobalContext } from "./context/context";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";

// PAGES
import Home from "./pages";
import Prices from "./pages/prices/index";
import About from "./pages/about/index";
import Services from "./pages/services/index";
import Contact from "./pages/contact/index";
import SignIn from "./pages/sign-in/index";
import SignUp from "./pages/sign-up/index";
import TermsOfUse from "./pages/terms-of-use/index";
import PrivacyPolicy from "./pages/privacy-policy/index";
import Guides from "./pages/guides/index";

// DASHBOARD PAGES
import Dashboard from "./pages/dashboard/index";
import Account from "./pages/dashboard/account";
import UpgradeAccount from "./pages/dashboard/UpgradeAccount";
import Deposit from "./pages/dashboard/deposit";
import DepositHistory from "./pages/dashboard/depositHistory";
import Withdrawal from "./pages/dashboard/withdrawal";
import WithdrawalHistory from "./pages/dashboard/withdrawalHistory";

function App() {
  const { isUser } = useGlobalContext();

  const [windowWidth, setWindowWidth] = useState(``);

  useEffect(() => {
    window.addEventListener(`resize`, () => {
      setWindowWidth(window.innerWidth);
    });
  }, [windowWidth]);

  // REDIRECT USERS THAT ARENT SIGNED IN BACK TO THE LOGIN PAGE
  const path = useLocation().pathname;
  const newPath = `/sign-in`;
  useEffect(() => {
    if (path.includes(`/dashboard`)) {
      if (!localStorage.getItem(`userDetails`)) {
        window.location.assign(newPath);
      }
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <main>
        <Navbar />
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/prices' element={<Prices />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/sign-in' element={<SignIn />} />
          <Route path='/sign-up' element={<SignUp />} />
          <Route path='/terms-of-use' element={<TermsOfUse />} />
          <Route path='/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/guides' element={<Guides />} />

          {/* DASHBOARD PAGES */}
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/dashboard/account' element={<Account />} />
          <Route
            path='/dashboard/upgrade-account'
            element={<UpgradeAccount />}
          />
          <Route path='/dashboard/deposit' element={<Deposit />} />
          <Route
            path='/dashboard/depositHistory'
            element={<DepositHistory />}
          />
          <Route path='/dashboard/withdrawal' element={<Withdrawal />} />
          <Route
            path='/dashboard/withdrawalHistory'
            element={<WithdrawalHistory />}
          />
        </Routes>
        {!isUser && <Footer />}
      </main>
    </>
  );
}

export default App;
