import { useGlobalContext } from "../../context/context";
import { useEffect, useState } from "react";
import ButtonLoader from "../loaders/ButtonLoader";
import PasswordChanged from "../modals/PasswordChanged";
import {
  validateEmail,
  validateResetPassword,
} from "../../data/formValidation";

const ForgotPassword = () => {
  const {
    setErrorMessage,
    errorMessage,
    setForgotPassword,
    setShowModal,
    showModal,
    baseURL,
    resetPage,
  } = useGlobalContext();

  const [resetError, setResetError] = useState(false);

  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState({
    email: ``,
    password: ``,
    seedPhrase: ``,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setNewPassword({ ...newPassword, [name]: value });
  };

  // FUNCTION TO RESET PASSWORD
  const resetPassword = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${baseURL}/auth/edit-password`, {
        method: "PUT",
        body: JSON.stringify(newPassword),
        headers: {
          "Content-type": "application/json",
        },
      });
      const data = await response.json();
      setLoading(false);
      if (response.status === 200) {
        setShowModal(true);
      } else {
        setResetError(true);
        setErrorMessage(data.message);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    resetPassword();
  };

  useEffect(() => {
    resetPage(`Reset Password`);
    // eslint-disable-next-line
  }, []);

  return (
    <section className='forgot-password'>
      <div className='form'>
        <h1>Reset Password</h1>
        <form onSubmit={handleSubmit}>
          {/* SEED PHRASE INPUT */}
          <div className='form-control'>
            <input
              type='text'
              placeholder='alpa-delta-beta-dog-omega-john'
              id='seed-phrase'
              className='input'
              name='seedPhrase'
              value={newPassword.seedPhrase}
              onChange={handleChange}
              required
            />
            <label htmlFor='seed-phrase' className='label'>
              Seed Phrase
            </label>
          </div>

          {/* EMAIL INPUT */}
          <div className='form-control'>
            <input
              type='email'
              placeholder='johnDoe@email.com'
              id='email'
              className='input'
              name='email'
              value={newPassword.email}
              onChange={handleChange}
              onKeyUp={() => validateEmail(`email`)}
              required
            />
            <label htmlFor='email' className='label'>
              Email
            </label>
          </div>

          {/* NEW PASSWORD INPUT */}
          <div className='form-control'>
            <input
              type='password'
              placeholder='**********'
              id='new-password'
              className='input'
              name='password'
              value={newPassword.password}
              onChange={handleChange}
              onKeyUp={() => validateResetPassword(`new-password`)}
              required
            />
            <label htmlFor='new-password' className='label'>
              New Password
            </label>
          </div>

          {/* ERROR MESSAGE */}
          {resetError && (
            <div className={`error ${resetError && `show-error`}`}>
              <p>{errorMessage}</p>
            </div>
          )}

          {/* LOGIN BUTTON */}
          <div className='password-submitBtn'>
            <p onClick={() => setForgotPassword(false)}>Login instead</p>
            <div className='btn'>
              <button className='purple'>Reset Password</button>
              {loading && <ButtonLoader />}
            </div>
          </div>
        </form>
      </div>
      {showModal && <PasswordChanged />}
    </section>
  );
};

export default ForgotPassword;
