// IMAGES FOR CRYPTO ASSETS
import Bitcoin from '../assets/bitcoin.svg';
import Ethereum from '../assets/ethereum-eth-logo.svg';
import Ripple from '../assets/xrp-xrp-logo.svg';
import BitcoinCash from '../assets/bitcoin-cash-bch-logo.svg';
import Litecoin from '../assets/litecoin-ltc-logo.svg';
import USDT from '../assets/tether-usdt-logo.svg';
import Eosio from '../assets/eos-eos-logo.svg';

// IMAGES FOR INVESTMENTS
import Cryptocurrency from '../assets/cryptocurrency.png';
import Etfs from '../assets/etfs.png';
import Options from '../assets/options.png';
import Bonds from '../assets/bond.png';
import StockMarket from '../assets/stock-market.png';
import IndexFunds from '../assets/index-funds.png';

// IMAGES FOR CONTACTS
import WhatsApp from '../assets/live-chat-support.svg';
import Phone from '../assets/phone.svg';
import Email from '../assets/email.svg';

// IMAGES FOR CUSTORMER REVIEWS
import customerOne from '../assets/aisha.jpg';
import customerTwo from '../assets/verdi.jpg';
import customerThree from '../assets/justin.jpg';

export const assets = [
  {
    logo: `${Bitcoin}`,
    name: `BITCOIN (BTC)`,
    info: `Bitcoin is an open-source software that since 2009 has enabled the exchange of an entirely new form of money (BTC) over the internet.`,
  },
  {
    logo: `${Ethereum}`,
    name: `ETHEREUM (ETH)`,
    info: `One of the most ambitious cryptocurrencies to date, Ethereum was launched in 2014 to decentralize products and services in a broader array of use cases beyond money.`,
  },
  {
    logo: `${Ripple}`,
    name: `RIPPLE (XRP)`,
    info: `XRP is a cryptocurrency that aims to complement traditional payments, migrating transactions that occur today between databases controlled by financial firms to a more open infrastructure.`,
  },
  {
    logo: `${BitcoinCash}`,
    name: `BITCOIN CASH (BCH)`,
    info: `Launched in 2017, Bitcoin Cash (BCH) is a newer cryptocurrency that intends to offer an alternative to the world's oldest and most widely traded cryptocurrency, Bitcoin (BTC).`,
  },
  {
    logo: `${Litecoin}`,
    name: `LITECOIN (LTC)`,
    info: `Litecoin (LTC) is one of the very first projects to copy and modify Bitcoin's code and use it to launch a new cryptocurrency.`,
  },
  {
    logo: `${Eosio}`,
    name: `EOSIO (EOS)`,
    info: `The cryptocurrency that powers the EOSIO blockchain, buying EOS is essential for developers who must buy and stake EOS to launch decentralized applications (dapps).`,
  },
];

export const investments = [
  {
    logo: `${Cryptocurrency}`,
    name: `CryptoCurreny`,
    info: `Cryptocurrencies like Bitcoin are digital currencies that are not backed by real assets or tangible securities. They are traded between consenting parties without a broker and tracked in a digital ledger.`,
  },
  {
    logo: `${Etfs}`,
    name: `ETFS`,
    info: `An exchange-traded fund (ETF) is a type of security that tracks indexes, sectors, commodities, or other assets, but can be bought and sold on the exchange in the same way as regular stocks. ETFs can be configured to track everything from single commodity prices to large and diverse stock collections. ETFs can also be configured to pursue specific investment strategies.`,
  },
  {
    logo: `${Options}`,
    name: `Options`,
    info: `The term option refers to a financial instrument based on the value of the underlying security, such as a stock. Option contracts provide buyers with the opportunity to buy or sell the underlying asset, depending on the type of contract they hold. Unlike futures, if the holder chooses not to buy or sell the asset, he or she does not need to buy or sell the asset. Each contract has a specific expiration date on which the holder must exercise the option.`,
  },
  {
    logo: `${Bonds}`,
    name: `Bonds`,
    info: `A bond is a bond that represents a loan from an investor to a borrower (usually a company or government). Bonds can be considered I.O.U. Provides loan and payment details between the lender and the borrower. Public debt is used by businesses, local governments, states, and state governments to fund projects and operations. The bond holder is the issuer's debtor or creditor..`,
  },
  {
    logo: `${StockMarket}`,
    name: `Stocks`,
    info: `Investing in stock means buying a small portion of the ownership of a listed company. These small stocks are known as company stocks, and when investing in them, you want the company to grow and function well over time. If so, your stock may increase in value, and other investors may be willing to buy them from you for more than you paid. This means that if you choose to sell them, you can make a profit.`,
  },
  {
    logo: `${IndexFunds}`,
    name: `Index Funds`,
    info: `An index fund is a type of investment fund or exchange traded fund (ETF) with a portfolio configured to match or duplicate the components of a financial market index, such as the Standard & Poor's 500 Index (S & P 500). Index mutual funds are designed to offer a wide range of market exposures, low operating costs and low portfolio turnover. These funds follow the benchmark index regardless of market conditions.`,
  },
];

export const contacts = [
  {
    logo: `${WhatsApp}`,
    method: `WhatsApp`,
    link: `whatsapp://send?text=Hello, my name is &phone=+16679008775`,
  },
  {
    logo: `${Phone}`,
    method: `Phone Support`,
    link: `/contact`,
  },
  {
    logo: `${Email}`,
    method: `Submit a ticket request`,
    link: `mailto: support@365livetrading.org`,
  },
];

export const getStartedCards = [
  {
    text: `I'm trying to take my crypto investments to the next level.`,
    link: `/sign-up`,
  },
  {
    text: `I'm new to crypto. I need someone to explain how it all works.`,
    link: `/guides`,
  },
  {
    text: `My firm is looking for a crypto investment solution.`,
    link: `/services`,
  },
  {
    text: `I'm looking for something my current crypto broker doesn't offer.`,
    link: `/about`,
  },
];

export const whatsNext = [
  {
    text: `I'm curious how much it will cost me to buy and sell cryptocurrency.`,
    link: `/prices`,
  },
  {
    text: `I'd like to learn more about why I should choose 365livetrading.`,
    link: `/about`,
  },
  {
    text: `I'm ready to try it out. Let's create my account.`,
    link: `/sign-up`,
  },
  {
    text: `Show me live crypto prices and how the market's moving right now.`,
    link: `/prices`,
  },
];

export const customerReviews = [
  {
    profileImg: `${customerOne}`,
    name: `Aisha Mohammed`,
    review: `This automated trading broker is a game changer. The platform is easy to use and the trades are always executed flawlessly. Customer support is top notch. Highly recommend.`,
  },
  {
    profileImg: `${customerTwo}`,
    name: `Heitor Verdi`,
    review: `As a beginner, I was hesitant to try an automated trading platform, but this broker has exceeded my expectations. The platform is user-friendly and the customer support team has been incredibly helpful. I'm already seeing great returns. Highly recommend to both novice and experienced traders.`,
  },
  {
    profileImg: `${customerThree}`,
    name: `Justin Shaifer`,
    review: `I've been using this broker for over a year and the service and profitability are unbeatable. The platform is intuitive and trade execution is always prompt and accurate. Customer support is knowledgeable and always available. Highly recommend giving them a try.`,
  },
];

export const planPrices = [
  {
    planName: `Starter`,
    planPrice: `$500.00 - $999.00`,
    planFeatures: [
      `eToro (Trading Bot)`,
      `20 positions`,
      `portfolio management`,
      `All Available Exchanges`,
    ],
  },
  {
    planName: `Premium`,
    planPrice: `$1000.00 - $2499.00`,
    planFeatures: [
      `CryptoHopper (Trading Bot)`,
      `80 positions`,
      `portfolio management`,
      `All Available Exchanges`,
    ],
  },
  {
    planName: `Master`,
    planPrice: `$2500.00 - $5499.00`,
    planFeatures: [
      `3Commas (Trading Bot)`,
      `200 positions`,
      `portfolio management`,
      `All Available Exchanges`,
    ],
  },
  {
    planName: `Pro`,
    planPrice: `$5500.00`,
    planFeatures: [
      `Pionex (Trading Bot)`,
      `500 positions`,
      `Portfolio Management`,
      `All coins for signals`,
      `Market Arbitrage`,
      `Algorithm Intelligence`,
    ],
  },
];

export const paymentMethods = [
  {
    method: `Bitcoin`,
    logo: Bitcoin,
    address: `bc1qd0etqhwsehq3tagafwefna9t0panpn6dg9achn`,
  },
  {
    method: `Ethereum`,
    logo: Ethereum,
    address: `0xb4eb0eAe120F435C17e14B676f5357e685c6e6A9`,
  },
  {
    method: `USDT`,
    logo: USDT,
    address: `0x78ae655622e47f2dA920671A7237f4B4bd400fD7`,
  },
];
