import { Link } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import { useGlobalContext } from "../../context/context";
import DashboardLinks from "./DashboardLinks";
import Links from "./Links";
import logo from "../../assets/logo.svg";

const Navbar = () => {
  const { isNavbar, setIsNavbar, handleClick, isUser, setIsUser } =
    useGlobalContext();

  return (
    <>
      <nav className={`${isNavbar ? `nav fixed` : `nav`}`}>
        <div className='nav-container'>
          <div className='nav_logo'>
            <Link to={`${isUser ? `/dashboard` : `/`}`}>
              <img src={logo} alt='logo' />
            </Link>
          </div>
          <div className='nav_links'>
            {isUser ? <DashboardLinks /> : <Links />}

            {isUser ? (
              <div className='nav_links_btns'>
                <Link
                  to='/'
                  onClick={() => {
                    setIsUser(false);
                    localStorage.clear(`userDetails`);
                    localStorage.clear(`userToken`);
                  }}
                >
                  <button type='button'>Logout</button>
                </Link>
              </div>
            ) : (
              <div className='nav_links_btns'>
                <Link to='/sign-in'>
                  <button type='button'>Sign in</button>
                </Link>
                <Link to='/sign-up'>
                  <button type='button' className='white'>
                    Create account
                  </button>
                </Link>
              </div>
            )}
          </div>
          <div className='hambugger'>
            {isNavbar ? (
              <FaTimes className='icon' onClick={() => setIsNavbar(false)} />
            ) : (
              <FaBars className='icon' onClick={() => setIsNavbar(true)} />
            )}
          </div>
        </div>
      </nav>
      <div className={`mobile_links ${isNavbar && `show`}`}>
        {isUser ? <DashboardLinks /> : <Links />}

        {isUser ? (
          <div className='mobile_links_btns'>
            <Link
              to='/'
              onClick={() => {
                handleClick();
                setIsUser(false);
                localStorage.clear(`userDetails`);
                localStorage.clear(`userToken`);
              }}
            >
              <button type='button'>Logout</button>
            </Link>
          </div>
        ) : (
          <div className='mobile_links_btns'>
            <Link to='/sign-in' onClick={handleClick}>
              <button type='button'>Sign in</button>
            </Link>
            <Link to='/sign-up' onClick={handleClick}>
              <button type='button' className='white'>
                Create account
              </button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default Navbar;
