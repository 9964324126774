import { useState, useEffect } from 'react';
import { useGlobalContext } from '../../context/context';
import { validateDepositAmount } from '../../data/formValidation';

import { paymentMethods } from '../../data/data';
import { RiFileCopyLine } from 'react-icons/ri';

const Deposit = () => {
  const [depositStep, setDepositStep] = useState(1);
  const { setIsUser, setUserToken, resetPage } = useGlobalContext();

  useEffect(() => {
    setUserToken(JSON.parse(window.localStorage.getItem(`userToken`)));
  }, [setUserToken]);

  useEffect(() => {
    if (window.localStorage.getItem(`userDetails`)) {
      setIsUser(true);
    }
  }, [setIsUser]);

  useEffect(() => {
    resetPage(`Deposit`);
    // eslint-disable-next-line
  }, []);

  return (
    <section className='deposit'>
      <section className='deposit-block'>
        {depositStep === 1 ? (
          <StepOne setDepositStep={setDepositStep} />
        ) : (
          <StepTwo />
        )}
      </section>
    </section>
  );
};

const StepOne = ({ setDepositStep }) => {
  const { setDepositAmount, depositAmount, isNavbar } = useGlobalContext();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setDepositAmount({ ...depositAmount, [name]: parseInt(value) });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <article className='deposit-info'>
      <h3>Make Deposit</h3>
      <form onSubmit={handleSubmit} className='deposit-form'>
        {/* DEPOSIT AMOUNT */}
        <div className='form-control'>
          <input
            type='number'
            placeholder='Enter a amount to deposit in USD (10000)'
            id='amount'
            className='input'
            name='amount'
            value={depositAmount.amount}
            onChange={handleChange}
            onKeyUp={() => validateDepositAmount(`amount`)}
            required
          />
          <label
            htmlFor='amount'
            className={isNavbar ? `navOpen label` : `label`}
          >
            Enter deposit amount in USD
          </label>
        </div>

        {/* MAKE DEPOSIT BUTTON */}
        <div className='submit-btn'>
          <button
            className='purple'
            onClick={() => {
              if (depositAmount.amount > 0) {
                setDepositStep(2);
              }
            }}
          >
            Make Deposit
          </button>
        </div>
      </form>
    </article>
  );
};

const StepTwo = () => {
  const { userToken, depositAmount, baseURL } = useGlobalContext();

  const sendDepositRequest = async () => {
    try {
      const response = await fetch(`${baseURL}/transaction/add`, {
        method: `POST`,
        body: JSON.stringify(depositAmount),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userToken}`,
        },
      });
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (e) => {
    const current = e.target.parentElement.nextSibling;
    current.classList.toggle(`show-address`);
  };

  const handleCopy = (e) => {
    const copyText = e.target.previousSibling.textContent;
    navigator.clipboard.writeText(copyText);
    e.target.nextSibling.classList.add(`show`);
    setTimeout(() => {
      e.target.nextSibling.classList.remove(`show`);
    }, 3000);
  };

  return (
    <article className='receipt'>
      <h1>Scan to make payment</h1>
      <p>
        Please select a payment method and make a deposit to the provided wallet
        address.
      </p>
      {paymentMethods.map((paymentMethod) => {
        const { method, logo, address } = paymentMethod;
        return (
          <div className='method'>
            <div className='crypto' onClick={handleClick}>
              <img src={logo} alt={method} />
              <h4>{method}</h4>
            </div>
            <div className='wallet-address'>
              <p>{address}</p>
              <RiFileCopyLine className='copy-btn' onClick={handleCopy} />
              <p className='confirmation'>Copied</p>
            </div>
          </div>
        );
      })}

      <p>
        After sucessfully making payment, ensure to submit payment receipt via
        email by clicking on the button below.
      </p>
      <a href='mailto: support@365livetrading.org?subject=Deposit Receipt'>
        <button
          className='purple'
          onClick={() => {
            setTimeout(() => {
              sendDepositRequest();
            }, 2000);
          }}
        >
          Upload Payment Receipt
        </button>
      </a>
    </article>
  );
};

export default Deposit;
